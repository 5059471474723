import * as React from 'react';
import ReactMarkdown from "react-markdown";
import {graphql} from 'gatsby';
import {Grid, Paper} from "@material-ui/core";
import {Layout} from "../common/Layout";
import {SEO} from "../components/seo";
import {Heading} from "../components/heading";

// Query to be run on build, passes resulting JSON as 'data' prop
export const query = graphql`
  query($pageId: Int!) {
    directusPage(directusId: { eq: $pageId }) {
        body
        directusId
        modified_by
        modified_on
        title
        status
        url_title
    }
  }
`;

const MarkdownPageTemplate = ({ pageContext, data }) => {
    const page = data.directusPage;
    return (
        <Layout>
            <SEO description={``} keywords={[`ccg`, `collectible`, `card`, `game`, `trade`]} title={page.title} />
            <section className="">
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Heading className={`mb-8`} text={page.title} />
                        <Paper className="p-4">
                            <ReactMarkdown className="markdown">{page.body}</ReactMarkdown>
                        </Paper>
                    </Grid>
                </Grid>
            </section>
        </Layout>
    );

};

export default MarkdownPageTemplate;
